@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;300&family=Poppins:wght@400&display=swap');
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

.header{
  display: flex;
padding: 0.5rem;
margin-left: 5px;
margin-right: 5px;
margin-top: 5px;
  flex-direction: row;
  border:cornsilk;
  background-color: #edd9d9;
  margin-bottom: 10vh;
  justify-content: space-between;
  max-width: 100vw;
}

.header .menu{
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}

.menu .logo{
  font-size: 300;
}

.my-logo{
  align-items: center;
  display: flex;
  font-weight: 900;
  margin: 0;
  padding: 8px;
}

.logout{
padding: 10px;
justify-self: end;
}

.menu button{
  background-color: transparent;
  color: #333;
  border: none;
  font-size: 15px;
}

.header .menu .link{
  padding: 10px 15px;
  text-decoration: none;
  color: #555;
}


.login-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
}

.button{
  background-color: #555;
  color: #fff;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 8px 10px;
}

.container{
  margin: auto;
  max-width: 80vw;
}

.m-2{
  margin-top: 20px;
}

.showcase{
  display: grid;
  grid-area: auto ;
}

.community-showcase{
  
  justify-content: space-between;
  max-width: 20vw;
  min-width: 20vw;
}

.post{
  width: 32%;
  max-width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.3);
}

.post img{
  position: relative;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.imageGen{
  max-width: 1000px;
  border-radius: 20px 40px;
  margin: 20px auto;
  padding: 10px;
  background-color: transparent;
}

.generate-form{
  display: flex;
  align-items: center;
  width: 100%;
}

.generate-form input{
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.generate-form input:focus{
  outline: 0.6px solid blue;
}

.generate-form button{
  margin-left: 10px;
  border: none;
  width: 100px;
  background-color: #04acfa;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.imageGen .result-image{
  max-width: 400px;
  margin: auto;
  margin-top: 20px;
}

.result-image img{
  width: 100%;
  border-radius: 5px;
  height: auto;
}

.result-image .action{
  display: flex;
}

.action button{
  background-color: #f5f9fb;
  color: #333;
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.loading{
  display: flex;
  width: 100%;
  text-align: center;
  margin: 70px auto;
  align-items: center;
}

.loading p{
  margin: auto;
  text-align: center;
}

.share-form{
  display: none;
}

.d-flex{
  display: flex;
  align-items: center;
}

h1 span{
  color: #04acfa;
}
 
.logo{
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  
}

.logo-link{
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  header{
    padding: 8px;
    font-size: 15px;
  }

  header .menu .link{
    padding: 8px 10px;
    font-size: 14px;
  }
}

.collection{
display: flex;
flex-wrap: wrap;
flex-direction: column;
}

.showcase{
  display:flex;
  flex-wrap: wrap;
}

.images-item{
  margin: 1rem;
}

.description{
  background-color: #978f8f;
  display: flex;
  flex-direction: column;
  height: 4rem;

}

.email-logo{
  margin-right: 3px;
}

.delete-button{
  border: none;
  background-color: white;
  margin-left: 3px;
  padding-top: 3px;
}

.logo-svg{
  display: flex;
}

.logo-svg img{
  margin-top: 23px;
  margin-right: 5px;
  
}

.main-home{
  margin-top: 5rem;
  margin-bottom: 4rem;

}

.main-home h1{
font-size: 40px;
color: rgb(13, 171, 239);
}

.main-home p{
  font-size: 19px;
  }

  .search-bar{
    width: 80vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

 .image-card img{
    border-radius: 2rem;
  }

  .image-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
  }
  .image-card-list{margin: 1rem;
    width: 22vw;
    position: relative;
    height: 40vh;}

    .card-text {
      margin-left: 3px;
    }